/*
 *   File : fund-investment.js
 *   Author URI : https://evoqins.com
 *   Description :Selecting payment method modal
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import React, { useState, useEffect, Fragment, useRef } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { Icon } from "../Icon";
import { LinkButton, PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomAmountInput, CustomPINInput, RadioGroup } from "../FormElements";
import { SIPDateModal } from ".";
import { CustomTabBar } from "../Tab";
import { ConsentCard } from "../Cards";

// Custom styles
import Color from '../../Styles/color.module.scss';
import style from "../../Styles/Components/payment-type.module.scss";

// Custom services
import { useCountdown } from "../../Helper/helper";
import { _checkMandate, _generate2Fa } from "../../Helper/api";
import APIService from "../../Services/api-service";

const AMOUNT_CHIP = [{
    id: 1,
    label: "+₹10,000",
    amount: 10000
},
{
    id: 2,
    label: "+₹20,000",
    amount: 20000
},
{
    id: 3,
    label: "+₹50,000",
    amount: 50000
}]

const TAB_DATA = [
    {
        id: 1,
        label: "Monthly SIP"
    },
    {
        id: 2,
        label: "Lumpsum"
    }
];

const SIP_DATA = [
    {
        id: 1,
        label: "Monthly SIP"
    },
];

const LUMPSUM_DATA = [
    {
        id: 2,
        label: "Lumpsum"
    }
];

const FIRST_INSTALLMENT = [{
    id: 1,
    name: "Yes"
},
{
    id: 2,
    name: "No"
}];

const FundInvestModal = React.memo((props) => {
    const termsRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;
    const { seconds, resetCountdown } = useCountdown(30);

    const [paymentType, setPaymentType] = useState(props.summary.payment_types[0]);
    const [sipAmount, setSipAmount] = useState(props.amount);
    const [sipAmountError, setSipAmountError] = useState("");

    const [lumpsumAmount, setLumpsumAmount] = useState(props.lumpsumAmount);
    const [lumpsumAmountError, setLumpsumAmountError] = useState("");

    const [selectedDate, setSelectedDate] = useState(props.selectedDate);
    const [selectedDateString, setSelectedDateString] = useState(props.selectedDateString);
    const [toggleDateModal, setToggleDateModal] = useState(false);

    const [isFirstInstallment, setIsFirstInstallment] = useState(props.isFirstInstallment);

    const [selectedTab, setSelectedTab] = useState(props.selected);

    const [submitLoader, setSubmitLoader] = useState(false);
    const [modalLoader, setModalLoader] = useState(false);
    const [termsError, setTermsError] = useState(null);


    const [screenNumber, setScreenNumber] = useState(1);
    const [otpRequestData, setOtpRequestData] = useState(null);

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [paymentModalData, setPaymentModalData] = useState({});

    const [acceptedTerms, setAcceptedTerms] = useState(false);



    useEffect(() => {
        var my_modal = document.getElementById("fund-invest");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        // eslint-disable-next-line
    }, []);

    // open sip date modal
    useEffect(() => {
        if (toggleDateModal === true) {
            const modal = new Modal(document.getElementById("sip-date"), {});
            modal.show();
        }
    }, [toggleDateModal]);

    // update sip amount and lumpsum
    useEffect(() => {
        if (props.amount) {
            setSipAmount(props.amount);
        }
        // else if (props.summary.min_investment.sip !== 0) {
        //     setSipAmount(props.summary.min_investment.sip);
        // }
        if (props.lumpsumAmount) {
            setLumpsumAmount(props.lumpsumAmount);
        }
        // else if (props.summary.min_investment.lumpsum !== 0) {
        //     setLumpsumAmount(props.summary.min_investment.lumpsum);
        // }
        // eslint-disable-next-line
    }, []);

    // handle payment type
    function _handlePayment(item) {
        setPaymentType(item);
    }

    // pay now handler
    function _handleFirstInstallment(id) {
        setIsFirstInstallment(id);
    }

    // handle sip amount
    function _handleAmount(input_value) {
        setSipAmount(input_value);
        setSipAmountError("");
    }

    // handle lumpsum amount
    function _handleLumpsum(input_value) {
        setLumpsumAmount(input_value);
        setLumpsumAmountError("");
    }

    // add amount to existing
    function _handleChipClick(item) {
        if (selectedTab === 1) {
            setSipAmount(prevAmount => (prevAmount !== "" ? prevAmount : 0) + item.amount)
            setSipAmountError("");
        }
        else {
            setLumpsumAmount(prevAmount => (prevAmount !== "" ? prevAmount : 0) + item.amount);
            setLumpsumAmountError("");
        }
    }

    // show sip date modal
    function _handleCloseModal(status) {
        setToggleDateModal(status);
    }

    // handle tab
    function _handleTabChange(value) {
        setSelectedTab(value);
        props.handleTabChange(value);
    }

    // handle sip date
    function _handleSelectDate(date, dateString) {
        setSelectedDate(date);
        setSelectedDateString(dateString)
    }

    // 1st screen submit handler
    function _handleInvest() {
        let valid = true;
        if (selectedTab === 1) {
            if (props.summary.mandate_status === false) {
                _createMandate();
            } else {
                if (props.summary.min_investment.sip !== 0) {
                    if (sipAmount === "") {
                        setSipAmountError("Enter monthly SIP amount");
                        valid = false;
                        return;
                    }
                    if (acceptedTerms === false) {
                        valid = false;
                        toast.dismiss();
                        setTermsError(true);
                        termsRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                        toast.error("Accept consent", {
                            type: "error",
                        });
                    }
                    if (valid) {
                        _confirmInvest();
                    }
                }
            }
        }
        else {

            if (props.summary.min_investment.lumpsum !== 0) {
                if (lumpsumAmount === "") {
                    setLumpsumAmountError("Enter lumpsum amount you wish to invest");
                    valid = false;
                    return;
                }
            }
            if (acceptedTerms === false) {
                valid = false;
                toast.dismiss();
                setTermsError(true);
                termsRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                toast.error("Accept consent", {
                    type: "error",
                });
            }
            if (valid) {
                _confirmInvest();
            }
        }
    }

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // handle submit otp validation
    async function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            try {
                const result = await _mfInvest();
                if (result) {
                    setScreenNumber(3);
                }
            } catch (error) {
                console.error("Error verifying OTP:", error);
            }
        }
    }

    // investment_type
    // 1 goal
    // 2 fund
    // 3 basket

    // status screen secondary button handler
    const _handleScreen3SecondaryButton = () => {
        if (selectedTab === 1 && isFirstInstallment === 2) {
            navigate("/systematic-transactions", {
                state: {
                    filter: 2,
                    filter_label: "Individual funds",
                    tab_index: 1,

                }
            })
        }
        else {
            if (paymentModalData.type === 0) {
                navigate("/orders", {
                    state: {
                        id: paymentModalData.order_id,
                        paymentTypes: [],
                        orderStatus: [],
                        orderTypes: [],
                        startDate: "DD-MM-YYYY",
                        endDate: "DD-MM-YYYY",
                        type: 2,
                        page: 1
                    }
                })
            } else {
                navigate("/orders/fund-order", {
                    state: {
                        id: paymentModalData.order_id,
                        paymentTypes: [],
                        orderStatus: [],
                        orderTypes: [],
                        startDate: "DD-MM-YYYY",
                        endDate: "DD-MM-YYYY",
                        type: 2,
                        page: 1
                    }
                })
            }
        }
    }

    // status screen primary button handler
    const _handleScreen3PrimaryButton = () => {
        props.invested()
        if (paymentModalData.type === 1) {
            navigate("/portfolio")
        }
        else {
            document.getElementById("close-modal").dispatchEvent(new Event("click"));

        }

    }

    const _otpLabel = () => {
        if (selectedTab === 1) {
            return isFirstInstallment === 2 ? "Verify & Schedule" : "Verify & Invest";
        }
        return "Verify & Invest";
    };

    const _investLabel = () => {
        if (selectedTab === 1) {
            return props.summary.mandate_status === true ?
                isFirstInstallment === 2 ? "Schedule SIP" : "Invest now"
                : "Create Mandate";
        }
        return "Invest now";
    };


    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("MF")
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };

    // API - Create mandate
    const _createMandate = () => {
        setSubmitLoader(true);
        _checkMandate(true).then((response) => {
            if (response.status_code === 200) {
                let mandate_window = window.open(response.data.mandate_url, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
                if (!mandate_window || typeof mandate_window.closed == 'undefined') {
                    toast.dismiss();
                    toast.error("Please enable pop-up permission");
                    return;
                };

                let timer = setInterval(function () {
                    if (mandate_window.closed) {
                        clearInterval(timer);
                        props.checkStatus()
                    }
                }, 1000);
            }

            setSubmitLoader(false);
        })
    }

    //API - confirm Invest
    const _confirmInvest = () => {
        setSubmitLoader(true)

        const url = "/mf/confirm-invest";
        const request = {
            "type": props.isReinvest ? "REINVEST" : null,
            "payment_type": paymentType.slug,
            "platform": "web",
            "sip": selectedTab === 1 ? {
                amount: sipAmount,
                date: selectedDate,
                get_first_installment: isFirstInstallment === 1 ? true : false
            } : null,
            "lumpsum": selectedTab === 2 ? {
                amount: lumpsumAmount
            } : null,
            "mf_id": props.summary.mf_id
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setSubmitLoader(false);
                setOtpRequestData(request);
                setScreenNumber(2);
                resetCountdown();
                setOtp("");
            } else {
                setSubmitLoader(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    // 2nd screen investing 
    // API - fund invest
    const _mfInvest = async () => {
        setModalLoader(true);
        const url = "/mf/invest";
        const request = {
            "type": props.isReinvest ? "REINVEST" : null,
            ...otpRequestData,
            otp: otp
        };

        try {
            const response = await APIService(true, url, request);
            if (response.status_code === 200) {
                if (response.data.payment_url) {
                    let payment_url = window.open(response.data.payment_url, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
                    if (!payment_url || typeof payment_url.closed == 'undefined') {
                        toast.dismiss();
                        toast.error("Please enable pop-up permission");
                        setModalLoader(false);
                        return false;
                    }

                    return await new Promise((resolve) => {
                        let timer = setInterval(function () {
                            if (payment_url.closed) {
                                clearInterval(timer);
                                _getPaymentStatus(response.data.payment_id, response.data.order_id);
                                setModalLoader(false);
                                resolve(true);
                            }
                        }, 1000);
                    });
                } else {
                    if (response.data.invested === true) {
                        setPaymentModalData({
                            status: response.data.invested,
                            image: require("../../Assets/Images/Modal/success.png"),
                            title: selectedTab === 1 && isFirstInstallment === 2 ?
                                "SIP scheduled successfully" :
                                "Payment successful",
                            type: 1,
                            description: selectedTab === 1 && isFirstInstallment === 2 ?
                                "Your SIP order has been scheduled successfully. It will auto debit on next scheduled date." :
                                "Your payment has been successful and the order has been submitted to the KRA. The allocation will be reflected in T+2 days."
                        });

                    } else {
                        setPaymentModalData({
                            status: response.data.invested,
                            image: require("../../Assets/Images/Modal/failed.png"),
                            title: "Payment failed",
                            type: 0,
                            description: "Your payment has been failed."
                        });
                    }
                    setModalLoader(false);
                    return true

                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                setModalLoader(false);
                return false;
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setModalLoader(false);
            return false;
        }
    }

    // API - payment status
    const _getPaymentStatus = (payment_id, order_id) => {
        const url = "/payment/check-status";
        const request = {
            payment_id: payment_id
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {

                if (response.data.status === true) {
                    setPaymentModalData({
                        order_id: order_id,
                        status: response.data.status,
                        image: require("../../Assets/Images/Modal/success.png"),
                        title: selectedTab === 1 && isFirstInstallment === 2 ?
                            "SIP scheduled successfully" :
                            "Payment successful",
                        type: 1,
                        description: selectedTab === 1 && isFirstInstallment === 2 ?
                            "Your SIP order has been scheduled successfully. It will auto debit on next scheduled date." :
                            "Your payment has been successful and the order has been submitted to the KRA. The allocation will be reflected in T+2 days."
                    });
                } else {
                    setPaymentModalData({
                        status: response.data.status,
                        image: require("../../Assets/Images/Modal/failed.png"),
                        title: "Payment failed",
                        type: 0,
                        description: "Your payment has been failed."
                    });
                }
                setModalLoader(false);
                return true

            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
        });
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_payment_modal}`}
                id="fund-invest"
                tabIndex="-1"
                aria-labelledby="fund-invest"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>

                    <div className={`modal-content pb-4 ${style.e_content} `}>
                        <Icon icon="close-btn"
                            size={24}
                            color={Color.eerie_black}
                            id="close-modal"
                            className="cursor-pointer d-none"
                            data-bs-dismiss="modal" />
                        {
                            (screenNumber === 1 || screenNumber === 2) &&
                            <div className="border-bottom-1px border-ash-gray px-4">
                                <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                    {
                                        screenNumber === 1 &&
                                        <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                            {
                                                location.pathname === "/explore/detail" ?
                                                    "Invest now"
                                                    :
                                                    "Invest More"
                                            }
                                        </h5>
                                    }
                                    {
                                        screenNumber === 2 &&
                                        <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                            <Icon icon="Arrow-Right"
                                                size={22}
                                                className="e-transform-left-arrow cursor-pointer me-2"
                                                onClick={() => setScreenNumber(1)} />  Enter OTP
                                        </h5>
                                    }
                                    <Icon icon="close-btn"
                                        size={24}
                                        color={Color.eerie_black}
                                        id="close-fund"
                                        className="cursor-pointer"
                                        data-bs-dismiss="modal" />
                                </div>
                            </div>
                        }
                        <div className={style.e_height}>
                            {
                                screenNumber === 1 ?
                                    <Fragment>
                                        <div className="box-shadow-black p-3 mx-4 gap-8px border-radius-16px mt-3 d-flex align-items-center">
                                            <img src={props.summary.image}
                                                alt="goal"
                                                width={38}
                                                height={38}
                                                className="box-shadow-black border-radius-12px" />
                                            <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{props.summary.name}</span>
                                        </div>

                                        {/* avaialbe investment types */}
                                        <div className="px-sm-4 px-3 ">
                                            <div className="border-bottom-1px border-chinese-white mt-2">
                                                <CustomTabBar data={props.summary.sip_available === false && props.summary.lumpsum_available === true ? LUMPSUM_DATA :
                                                    props.summary.sip_available === true && props.summary.lumpsum_available === false ? SIP_DATA : TAB_DATA}
                                                    selectedTab={selectedTab}
                                                    onSelectTab={_handleTabChange} />
                                            </div>
                                        </div>

                                        {/* investment amount input */}
                                        <div className="mt-3 mx-4">
                                            {
                                                selectedTab === 1 ?
                                                    <CustomAmountInput value={sipAmount}
                                                        error={sipAmountError}
                                                        key={"SipAmount"}
                                                        label="Investment Amount"
                                                        placeholder="50,000"
                                                        handleChange={_handleAmount} />
                                                    :
                                                    <CustomAmountInput value={lumpsumAmount}
                                                        error={lumpsumAmountError}
                                                        key={"LumpsumAmount"}
                                                        label="Investment Amount"
                                                        placeholder="50,000"
                                                        handleChange={_handleLumpsum} />
                                            }

                                            <div className="d-flex flex-wrap gap-12px mt-4 pt-2 mb-3">
                                                {
                                                    AMOUNT_CHIP.map((item, key) =>
                                                        <span className="px-3 py-2 border-bright-gray border-1px e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black border-radius-20px cursor-pointer user-select-none"
                                                            key={key}
                                                            onClick={() => _handleChipClick(item)}>{item.label}</span>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        {/* SIP date input */}
                                        {
                                            selectedTab === 1 &&
                                            <Fragment>
                                                <p className="e-font-14 e-line-height-24 e-montserrat-medium color-eerie-black mb-2 mx-4">
                                                    Select SIP date
                                                </p>

                                                {/* SIP component */}
                                                <div className="padding-14px-tb px-3 bg-white box-shadow-charleston border-1px border-light-grayish-blue d-flex justify-content-between align-items-center border-radius-12px cursor-pointer mx-4 mb-3"
                                                    onClick={() => _handleCloseModal(true)}>
                                                    <span className="color-rebecca-purple e-font-14 e-montserrat-semi-bold e-line-height-18">{selectedDateString} of every month</span>
                                                    <Icon icon="dob"
                                                        size={24} />
                                                </div>
                                            </Fragment>
                                        }

                                        {/* show first instalment selection if investment tyupe is SIP */}
                                        {
                                            selectedTab === 1 &&
                                            <div className="mx-4">
                                                <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                                    Pay first installment now?
                                                </p>

                                                <RadioGroup data={FIRST_INSTALLMENT}
                                                    selected={isFirstInstallment}
                                                    handleSelect={_handleFirstInstallment} />

                                                {
                                                    isFirstInstallment === 1 ?
                                                        <div className="d-flex align-items-center gap-8px e-bg-ivory-bridge p-2 pe-4 border-radius-12px mb-4 mt-2">
                                                            <Icon icon="info-circle-yellow"
                                                                size={18} />
                                                            <p className="e-montserrat-regular e-font-12 e-line-height-18 color-vivid-orange mb-0">Your first SIP will be deducted immediately.</p>
                                                        </div>
                                                        :
                                                        isFirstInstallment === 2 ?
                                                            <div className="d-flex align-items-center gap-8px e-bg-ivory-bridge p-2 pe-4 border-radius-12px mb-4 mt-2">
                                                                <Icon icon="info-circle-yellow"
                                                                    size={18} />
                                                                <p className="e-montserrat-regular e-font-12 e-line-height-18 color-vivid-orange mb-0">Your first SIP shall start from the selected date.</p>
                                                            </div>
                                                            :
                                                            null
                                                }
                                            </div>
                                        }

                                        {selectedTab === 1 && isFirstInstallment === 1 ? <Fragment>
                                            <h5 className='e-font-14  color-eerie-black e-montserrat-semi-bold e-line-height-22 mx-4 mb-0'>
                                                Payment Method
                                            </h5>
                                            {
                                                props.summary.payment_types.map((item, index) => {
                                                    return (
                                                        <div key={index}
                                                            className={`py-2 mx-4 padding-12px-lr d-flex align-items-center justify-content-between border-1px cursor-pointer mt-3 border-radius-12px  ${item.id === paymentType.id ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`} onClick={() => _handlePayment(item)}>
                                                            <div className="d-flex align-items-center gap-8px">
                                                                <img src={item.image} alt={"Basket"}
                                                                    draggable={false}
                                                                    width={46}
                                                                    height={32} />
                                                                <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                                                    {item.label}
                                                                </span>
                                                            </div>
                                                            <Icon icon={item.id === paymentType.id ? 'radio-checked1' : 'radio-unchecked'}
                                                                size={24} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                            :
                                            selectedTab === 2 ?
                                                <Fragment>
                                                    <h5 className='e-font-14  color-eerie-black e-montserrat-semi-bold e-line-height-22 mx-4 mb-0'>
                                                        Payment Method
                                                    </h5>
                                                    {
                                                        props.summary.payment_types.map((item, index) => {
                                                            return (
                                                                <div key={index}
                                                                    className={`py-2 mx-4 padding-12px-lr d-flex align-items-center justify-content-between border-1px cursor-pointer mt-3 border-radius-12px  ${item.id === paymentType.id ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`} onClick={() => _handlePayment(item)}>
                                                                    <div className="d-flex align-items-center gap-8px">
                                                                        <img src={item.image} alt={"Basket"}
                                                                            draggable={false}
                                                                            width={46}
                                                                            height={32} />
                                                                        <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                                                            {item.label}
                                                                        </span>
                                                                    </div>
                                                                    <Icon icon={item.id === paymentType.id ? 'radio-checked1' : 'radio-unchecked'}
                                                                        size={24} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                                :
                                                null}
                                        <div className="mx-4 mb-3"
                                            ref={termsRef}>
                                            <ConsentCard acceptedTerms={acceptedTerms}
                                                error={termsError}
                                                setAcceptedTerms={() => {
                                                    setAcceptedTerms(!acceptedTerms);
                                                    setTermsError(false);
                                                }} />
                                        </div>

                                        {
                                            props.showInfo &&
                                            <div className="d-flex align-items-start padding-12px-all  e-bg-light-blue border-radius-12px mx-4 color-ocean-blue e-font-12 e-line-height-18 gap-4px e-montserrat-medium">
                                                <span>
                                                    <Icon icon="info-circle"
                                                        size={16} />
                                                </span>
                                                Your investments will allocate to folios which you already invested
                                            </div>
                                        }
                                    </Fragment>
                                    :
                                    screenNumber === 2 ?
                                        <div className="px-4 padding-14px-top">
                                            <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                                An OTP has been sent to your PAN linked mobile
                                                number.
                                            </p>
                                            <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">+91-  {MASKED_NUMBER}</h6>
                                            <CustomPINInput id="otp"
                                                autoFocus={true}
                                                pinString={otp}
                                                error={otpError}
                                                postfix="*"
                                                label="Enter OTP"
                                                onSubmit={_submitOTP}
                                                handleChange={_handleOTP} />
                                            <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                            ${seconds === 0 ?
                                                    'cursor-pointer'
                                                    : 'disabled'}`}
                                                onClick={_handleResend}>
                                                {seconds === 0 ?
                                                    'Resend OTP'
                                                    : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                            </span>
                                            </p>

                                        </div>
                                        :
                                        <div className="text-center px-4">
                                            <img src={paymentModalData.image}
                                                alt={paymentModalData.title}
                                                draggable={false}
                                                width={144}
                                                height={144}
                                                className="mx-auto" />
                                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">
                                                {paymentModalData.title}
                                            </h5>

                                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mt-2 mb-0 ">
                                                {paymentModalData.description} {paymentModalData.type === 1 && isFirstInstallment === 1 && <LinkButton label="View TAT"
                                                    className="e-font-14"
                                                    onPress={() => window.open(`${window.location.origin}/view-tat`, "_blank")} />}
                                            </p>
                                        </div>
                            }
                        </div>
                        {
                            screenNumber === 1 ?
                                <div className="row d-flex justify-content-center">
                                    <div className="col-6 ">
                                        <PrimaryButton label={_investLabel()}
                                            className="w-100 padding-10px-tb mt-4"
                                            loader={submitLoader}
                                            disabled={submitLoader}
                                            onPress={_handleInvest} />
                                    </div>
                                </div> :
                                screenNumber === 2 ?
                                    <div className="px-4 mt-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <SecondaryButton label="Cancel"
                                                    className="padding-12px-tb w-100 e-bg-magnolia"
                                                    cancel="modal" />
                                            </div>
                                            <div className="col-6">
                                                <PrimaryButton label={_otpLabel()}
                                                    className="padding-12px-tb  w-100"
                                                    disabled={modalLoader}
                                                    onPress={_submitOTP} />
                                            </div>
                                        </div>

                                    </div> :
                                    <div className="row px-4 d-flex align-items-center justify-content-center mt-4">

                                        <div className="col-6">
                                            <SecondaryButton label={selectedTab === 1 && isFirstInstallment === 2 ? "View SIP" : "View Order"}
                                                cancel="modal"
                                                className="padding-12px-tb w-100 e-bg-magnolia"
                                                onPress={_handleScreen3SecondaryButton} />
                                        </div>


                                        <div className="col-6">
                                            <PrimaryButton label={paymentModalData.type === 1 ? "Portfolio" : "Go back"}
                                                className=" padding-12px-tb w-100"
                                                cancel="modal"
                                                onPress={_handleScreen3PrimaryButton} />
                                        </div>

                                    </div>


                        }

                    </div>

                </div>
                {
                    (toggleDateModal === true) &&
                    <Fragment>
                        <SIPDateModal apiDates={props.summary.sip_dates !== null ? props.summary.sip_dates : []}
                            selectedSIPDate={selectedDate}
                            handleSelect={_handleSelectDate}
                            backdrop={!toggleDateModal}
                            handleCloseDateModal={() => _handleCloseModal(false)} />
                        <div className="position-fixed bg-black opacity-1 w-100 h-100 z-index-4 top-0 start-0">
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
    )
})

FundInvestModal.defaultProps = {
    selected: 1,
    handleTabChange: () => { },
    amount: "",
    lumpsumAmount: ""
}

export default FundInvestModal;
