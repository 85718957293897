/*
 *   File : financial-summary.js
 *   Author : https://evoqins.com
 *   Description : Container file which shows investments made against each investment type by the user.
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";

// import components
import { Breadcrumb, Loader } from "../../Components/Other";
import { Header } from "../../Components/Header";
import { Icon } from "../../Components/Icon";
import { InvestmentStatusModal, UpdateFinancialStatusModal } from "../../Components/Modal";

// import services
import { _getFinancialStatus } from "../../Helper/api";
import APIService from "../../Services/api-service";
import { PrimaryButton } from "../../Components/Buttons";

const PATTERNS = [
    require("../../Assets/Images/Home/pattern.png"),
    require("../../Assets/Images/Home/growth-pattern.png"),
    require("../../Assets/Images/Home/savings-pattern.png"),
    require("../../Assets/Images/Home/protection-pattern.png"),
]

const FinancialSummary = () => {
    const navigate = useNavigate();

    const [screenLoader, setScreenLoader] = useState(true);
    const [financialSummary, setFinancialSummary] = useState({});

    const [investment, setInvestment] = useState(null);

    const [confirmInvestment, setConfirmInvestment] = useState(false);
    const [assetType, setAssetType] = useState(null);

    const [familyStructureAmount, setFamilyStructureAmount] = useState('Ideal mediclaim will be 10lacs.');

    useEffect(() => {
        _getFinancialSummary();
        _getHomeDetails();
    }, []);

    useEffect(() => {
        if (investment !== null) {
            const modal = new Modal(document.getElementById("update-financial-status"));
            modal.show();
        }
    }, [investment]);

    useEffect(() => {
        if (confirmInvestment === true) {
            const modal = new Modal(document.getElementById("investment-status"));
            modal.show();
        }
    }, [confirmInvestment]);

    // navigate to home
    function _handleNavigate() {
        navigate("/");
    }

    // navigate back
    function _handleCurrentNavigate() {
        navigate("/financial-freedom");
    }

    // update
    function _updateInvestment() {
        setConfirmInvestment(false);
        _getFinancialSummary();
    }

    // handle current value
    function _handleInvestment(item, is_submitted, index) {
        setAssetType(index);
        setInvestment(item);
        if (is_submitted === true) {
            setConfirmInvestment(is_submitted);
        }
    }

    // API - get home data
    const _getHomeDetails = (type) => {
        const url = "/home/get"

        const request = {
            platform: "web"
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (response.data.amount_label) {
                    setFamilyStructureAmount(response.data.amount_label)
                }
            }
        })
    }

    // API - get financial status
    const _getFinancialSummary = () => {
        _getFinancialStatus().then((response) => {
            setFinancialSummary(response.data);
            setScreenLoader(false);
        });
    }

    const _getHeading = (assetType, item_id) => {
        if (assetType === 3) {
            switch (item_id) {
                case 11:
                    return "Hospitalization cover";
                default:
                    return "Input values as required";
            }
        } else {
            return "Input values as required";
        }
    };

    // Function to get inputLabel based on assetType and item_id
    const _getInputLabel = (assetType, item_id) => {
        if (assetType === 3) {
            switch (item_id) {
                case 9:
                    return "Current life cover";
                case 10:
                    return "Current accidental disability cover";
                case 11:
                    return "Current mediclaim cover";
                case 12:
                    return "Current emergency corpus";
                default:
                    return "Current value of your holdings";
            }
        } else {
            return "Current value of your holdings";
        }
    };

    // Function to get blueLabel based on assetType and item_id
    const _getBlueLabel = (assetType, item_id) => {
        if (assetType === 3) {
            switch (item_id) {
                case 9:
                    return "Ideal life cover will be 20 times of your annual income.";
                case 10:
                    return "Ideally accidental disability cover should be 1cr.";
                case 11:
                    return familyStructureAmount;
                case 12:
                    return "Ideally emergency corpus expected is 6 months of your expenses.";
                default:
                    return "";
            }
        } else {
            return "";
        }
    };

    // Function to get blueLabel based on assetType and item_id
    const _getTooltipLabel = (assetType, item_id) => {
        switch (item_id) {
            case 1:
                return "Please enter current value of your Mutual Fund holdings.";
            case 2:
                return "Please input current value of your Real Estate holdings.";
            case 3:
                return "Please input current value of your holdings through PMS / AIF.";
            case 4:
                return "Please input current value of your holdings in Stocks.";
            case 5:
                return "Please input current value of your investments in Fixed Deposit(s).";
            case 6:
                return "Please input current value of your holdings in PPF.";
            case 7:
                return "Please input current value of your holdings in Gold.";
            case 8:
                return "Please input your current value of premium paid with LIC.";
            case 9:
                return "Please input your Sum Assured through Life or Term Insurance policy(ies).";
            case 10:
                return "Please input Sum Assured of your Accidental Disability policy(ies).";
            case 11:
                return "Please input Sum Assured of your Mediclaim policy(ies).";
            case 12:
                return "Please input the amount that you have set aside for emergencies.";
            default:
                return "";
        }

    };

    // Use these functions to get the labels
    const heading = investment ? _getHeading(assetType, investment.item_id) : '';
    const inputLabel = investment ? _getInputLabel(assetType, investment.item_id) : '';
    const blueLabel = investment ? _getBlueLabel(assetType, investment.item_id) : '';
    const tooltipLabel = investment ? _getTooltipLabel(assetType, investment.item_id) : '';


    return (
        <Fragment>
            {
                screenLoader === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                        <Header type={2} />
                        <div className="row ">
                            <div className="col-lg-11 padding-80px-bottom">
                                <section className="px-4 ">
                                    <Breadcrumb mainPage="Home"
                                        currentPage="Financial pyramid"
                                        childPage="Financial Pyramid Update"
                                        type={2}
                                        handleParentNavigate={_handleNavigate}
                                        handleCurrentNavigate={_handleCurrentNavigate} />
                                    <Icon icon="Arrow-Right"
                                        size={32}
                                        className="mb-3 margin-32px-top e-transform-left-arrow cursor-pointer"
                                        onClick={_handleCurrentNavigate} />
                                    <div className="row">

                                        <div className="col-xl-8 col-lg-7 col-md-6">
                                            {
                                                Object.entries(financialSummary.data).map(([key, summary], index) => {
                                                    return (
                                                        <Fragment>
                                                            <div key={index}
                                                                className={`border-radius-24px pt-sm-4 pt-3 pb-3 position-relative box-shadow-black ${index > 0 ? "margin-32px-bottom" : ""}`} style={{ backgroundColor: summary.color }}>
                                                                <div className={`d-flex align-items-center gap-8px color-eerie-black e-montserrat-semi-bold e-font-16 e-line-height-20 pb-3 px-sm-4 px-3 ${summary.status === true ? "border-bottom-1px border-bottom-black-0D" : ""}`}>
                                                                    <img src={summary.image}
                                                                        alt={key}
                                                                        width={32}
                                                                        draggable={false} />
                                                                    {key}
                                                                </div>
                                                                {
                                                                    summary.status === false ?
                                                                        <div className="py-1 px-sm-4 px-3 e-font-16 e-line-height-24 e-montserrat-medium color-eerie-black text-center">
                                                                            We prioritize your financial well-being by focusing on secure and stable growth, also avoiding high-risk speculation.
                                                                        </div>
                                                                        :
                                                                        <div className="pt-3 px-sm-4 px-3">
                                                                            <h6 className="e-font-14 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">Current value</h6>
                                                                            {
                                                                                summary.data.map((item, fund_index) => {
                                                                                    return (
                                                                                        <div className={`d-flex justify-content-between flex-wrap py-sm-3 py-2 my-1 ${fund_index > 0 ? "border-top-1px border-bottom-black-0D" : ""}`}>
                                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                                <img src={item.image} draggable={false}
                                                                                                    width={28}
                                                                                                    alt={key} />
                                                                                                <p className="e-font-16 e-line-height-24 e-montserrat-medium mb-0">
                                                                                                    {item.name}: <span className="e-montserrat-semi-bold">{item.value_text}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                            <button className="e-add-btn py-2 px-3 color-rebecca-purple e-font-14 e-line-height-16 e-montserrat-bold" onClick={() => _handleInvestment(item, false, index)}>
                                                                                                {item.value === null ? 'Add' : 'Update'}
                                                                                            </button>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                }
                                                                <img src={PATTERNS[index]}
                                                                    alt="pattern"
                                                                    draggable={false}
                                                                    className="position-absolute h-100 top-0 end-0" />
                                                            </div>
                                                            {
                                                                summary.status === false && <p className="e-montserrat-medium e-font-16 e-line-height-30 color-davy-gray mb-2 mt-3 pt-1">
                                                                    Please add details below to have a complete picture of your Status as per Financial Pyramid.
                                                                </p>}
                                                        </Fragment>
                                                    )
                                                })
                                            }

                                            {financialSummary.is_pyramid_updated === true && <div className="col-sm-4 col-7 pb-3">
                                                <PrimaryButton label="Submit & View result"
                                                    className="py-2 w-100"
                                                    onPress={() => {
                                                        navigate(-1);
                                                        window.scrollTo(0, 0);
                                                    }} />
                                            </div>}

                                            {/* Disclaimer */}
                                            <p className="e-montserrat-regular e-font-16 e-line-height-30 color-davy-gray mb-0">Disclaimer:</p>
                                            <p className="e-montserrat-regular e-font-16 e-line-height-30 color-davy-gray mb-0">
                                                Rest assured, your data is protected. It will be used only to generate a personalized financial pyramid analysis, helping you to make the best financial choices for your future.
                                            </p>

                                        </div>
                                        {/* <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7 pt-lg-0 pt-3">
                                            <div className="e-max-potential p-4 d-flex justify-content-center text-center border-radius-24px flex-column">
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-7">
                                                        <img src={require("../../Assets/Images/financial-freedom/maximum-growth-potential.png")}
                                                            alt="Maximum growth potential"
                                                            draggable={false}
                                                            className="img-fluid" />
                                                    </div>
                                                </div>
                                                <p className="e-montserrat-semi-bold e-font-18 e-line-height-24 mb-2 mt-4 color-eerie-black">Maximize Growth Potential</p>
                                                <p className="e-montserrat-medium e-font-14 e-line-height-24 color-davy-gray margin-40px-bottom">
                                                    Solid financial foundation and leverage opportunities for significant growth
                                                </p>
                                                <PrimaryButton label="Invest Now"
                                                    className="padding-12px-tb w-100 "
                                                    onPress={() => navigate('/milestone')} />
                                            </div>
                                        </div> */}
                                    </div>

                                </section>
                            </div>
                        </div>
                    </div>
            }

            {
                investment !== null && <UpdateFinancialStatusModal
                    summary={investment}
                    heading={heading}
                    title={investment.item_id === 12 ? "Current Savings" : assetType === 3 ? "Current Sum Assured" : "Add investment amount"}
                    inputLabel={inputLabel}
                    blueLabel={blueLabel}
                    assetType={assetType}
                    tooltipLabel={tooltipLabel}
                    closeModal={() => _handleInvestment(null, false, null)}
                    statusUpdated={(amount) => _handleInvestment(null, true, null)}
                />
            }

            {
                confirmInvestment === true && <InvestmentStatusModal
                    title="Grow status updated successful"
                    content=""
                    closeModal={_updateInvestment}
                />
            }
        </Fragment>
    )
}
export default FinancialSummary