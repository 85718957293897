/*
*   File : calculated-risk.js
*   Author : https://evoqins.com
*   Description : Risk score    
*   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";

// components
import { GaugeChart, HorizontalStackedBar } from "../../../Components/Charts"
import { PrimaryButton } from "../../../Components/Buttons"
import { ConfigureGoalModal } from "../../../Components/Modal";

// helper
import { _getProfile } from "../../../Helper/api";


const RiskScore = (props) => {

    const RISK_ASSESSMENT_DONE = useSelector((state) => state.Reducer.PROFILE_DETAILS);

    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (openModal === true) {
            const modal = new Modal(document.getElementById("configure-goal"));
            modal.show();
        }
    }, [openModal]);

    const _openModal = () => {
        if (RISK_ASSESSMENT_DONE.risk_assessment_done) {
            navigate("/", {
                state: {
                    basket: true
                }
            });
        }
        else {
            setOpenModal(true);
        }
    }

    function _handleSkip() {
        _getProfile();
        if (props.from_fund) {
            navigate("/explore/detail", {
                state: {
                    data: {
                        mf_id: props.mf_id
                    }
                }
            })
        } else {
            navigate("/")
        }
    }

    const _handleClose = () => {
        _getProfile();
        navigate("/milestone")
    }

    return (
        <Fragment>
            <div className="border-bottom-1px border-bright-gray pb-3">
                <p className="e-montserrat-semi-bold e-font-16 e-line-height-28 color-dark-jungle-green mb-1 mt-3">Recommended Risk Profiling Score</p>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black">This score is a guidance, which will enable you to make informed investment decisions.</p>
                <div className="e-risk-assessment-card pb-4 pt-0 px-4 d-flex justify-content-center border-radius-24px flex-column">
                    <div className="row d-flex justify-content-center text-center">
                        <div className="col-sm-7 position-relative">
                            <GaugeChart value={props.chartData.risk_score.score * 10}
                                show_labels={false}
                                risk_score={true} />
                            <div className="position-absolute start-0 end-0 top-50-percent mt-2">
                                <span className="e-font-14 e-line-height-20 e-montserrat-regular color-black-olive" >
                                    Risk score
                                </span>
                                <h6 className="e-font-32 e-line-height-32 e-montserrat-semi-bold color-dark-jungle-green  mb-0" >{props.chartData.risk_score.score}</h6>
                                <p className={`e-montserrat-regular e-font-14 e-line-height-24 ${props.chartData.investment_style_text === "Conservative" ? "color-kelly-green" : props.chartData.investment_style_text === "Moderate" ? "color-sunglow" : props.chartData.investment_style_text === "Aggressive" ? "color-tangerine" : "color-pastel-red"}`}>{props.chartData.investment_style_text}</p>
                            </div>
                        </div>
                    </div>
                    <p className="e-montserrat-regular e-font-14 e-line-height-24 mx-lg-5 color-eerie-black text-center mt-3 px-lg-5 mb-0">{props.chartData.risk_score.score_text}</p>
                </div>
                <p className="e-montserrat-semi-bold e-font-16 e-line-height-28 color-dark-jungle-green mb-1 mt-3">Category distribution</p>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black">Based on your risk score, following is the recommended distribution: <span className={`e-montserrat-semi-bold ${props.chartData.investment_style_text === "Conservative" ? "color-kelly-green" : props.chartData.investment_style_text === "Moderate" ? "color-sunglow" : props.chartData.investment_style_text === "Aggressive" ? "color-tangerine" : "color-pastel-red"}`} >{props.chartData.investment_style_text}</span></p>
                <HorizontalStackedBar allocations={props.chartData.graph_data} />
            </div>
            <div className="row justify-content-center">
                <div className="col-sm-3 col-6 d-flex justify-content-center">
                    <PrimaryButton label={RISK_ASSESSMENT_DONE.risk_assessment_done ? "View Funds & Invest" : "Continue with Investment"}
                        className=" padding-12px-tb px-4 mt-4 mx-auto"
                        onPress={_openModal} />
                </div>
            </div>

            {
                openModal === true &&
                <ConfigureGoalModal closeModal={() => setOpenModal(false)}
                    milestone={_handleClose}
                    home={() => _handleSkip()} />
            }
        </Fragment >
    )
}

export default RiskScore