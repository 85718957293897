/*
 *   File : model-portfolio.js
 *   Author : https://evoqins.com
 *   Description : Model portfolio card
 *   Integrations : null
 *   Version : 1
*/

// import packages
import React, { Fragment, useEffect, useState } from "react";

// import components
import { HorizontalStackedBar } from "../Charts";
import { CustomTabBar } from "../Tab";
import { LinkButton, PrimaryButton } from "../Buttons";
import { Modal } from "bootstrap";
import { CustomFundAllocation, InvestMoreModal, RiskAssessmentReview, RiskKycConfirmationModal } from "../Modal";
import { useNavigate } from "react-router-dom";
import { _formatSip, _getTomorrowDate, _validatingKycStatus } from "../../Helper/helper";
import { toast } from "react-toastify";
import APIService from "../../Services/api-service";
import { useSelector } from "react-redux";

const TAB_DATA = [
    {
        label: "Active",
        id: 1
    },
    {
        label: "Passive",
        id: 2
    }
]

const ModelPortfolioCard = React.memo((props) => {
    const SIGNUP_PROGRESS = useSelector(state => state.Reducer.SIGNUP_PROGRESS);
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(TAB_DATA[0].id);
    const [riskAssessment, setRiskAssessment] = useState(false);

    const [portfolioData, setPortfolioData] = useState(props.data.active_portfolio);
    const [showFundAllocation, setShowFundAllocation] = useState(false);
    const [modalData, setModalData] = useState({});
    const [submitLoader, setSubmitLoader] = useState(false);
    const [showGoalModal, setShowGoalModal] = useState(false);
    const [goalInvestMore, setGoalInvestMore] = useState(null);

    const [investmentSuggestion, setInvestmentSuggestion] = useState({});
    const [riskKycCheck, setRiskKycCheck] = useState(false);


    useEffect(() => {
        if (riskAssessment === true) {
            const modal = new Modal(document.getElementById('risk-assessment'));
            modal.show();
        }
    }, [riskAssessment]);

    useEffect(() => {
        if (showFundAllocation === true) {
            const modal = new Modal(document.getElementById("fund-allocation"));
            modal.show()
        }
    }, [showFundAllocation]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    useEffect(() => {
        if (props.basketTypeId !== null) {
            _getInvestmentSuggestions();
        }
        // eslint-disable-next-line
    }, [props.basketTypeId])


    useEffect(() => {
        if (tabIndex === 1) {
            setPortfolioData(props.data.active_portfolio)
        } else {
            setPortfolioData(props.data.passive_portfolio)
        }
        // eslint-disable-next-line
    }, [tabIndex]);

    useEffect(() => {
        if (Object.keys(portfolioData).length !== 0) {
            setModalData(Object.assign({}, portfolioData.allocation_data));
        }

    }, [portfolioData]);

    useEffect(() => {
        if (showGoalModal === true) {
            const modal = new Modal(document.getElementById("invest-more"))
            modal.show();
        }
    }, [showGoalModal]);


    function _handleTab(tab_index) {
        setTabIndex(tab_index);
    }

    function _reviewAssessment(status) {
        setRiskAssessment(status);
    }

    // show allocation modal 
    function _openAllocationModal() {
        setShowFundAllocation(true);
    }

    function _closeAllocationModal() {
        setShowFundAllocation(false);
    }

    function _handleCompare() {
        navigate("/active-vs-passive", {
            state: {
                goal_id: props.basketTypeId,
            }
        })
    }

    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    function _handleNavigateToScreen() {
        const kyc_status = SIGNUP_PROGRESS.kyc_data.status
        if (_validatingKycStatus(kyc_status) === 1) {
            navigate("/kyc");
        } else if (_validatingKycStatus(kyc_status) === 2) {
            toast.dismiss();
            toast.error("Please complete KYC", {
                type: "error"
            })
        }
    }

    function _handleInvest() {
        const kyc_status = SIGNUP_PROGRESS.kyc_data.status
        if (_validatingKycStatus(kyc_status) === 1) {
            _confirmRiskKyc(true)
        } else if (_validatingKycStatus(kyc_status) === 4) {
            navigate("/");
        } else if (_validatingKycStatus(kyc_status) === 2) {
            _confirmRiskKyc(true)
        } else {
            setSubmitLoader(true);
            setShowGoalModal(true);
        }
    }

    const _handleCloseInvestMoreModal = () => {
        setGoalInvestMore(null);
        setShowGoalModal(false);
        setSubmitLoader(false);
    }


    // API - Get Investment Suggestions
    const _getInvestmentSuggestions = (mandate_check = false) => {
        const url = "/goal/basket-suggestion";


        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setGoalInvestMore(props.data);
                const data = {
                    ...response.data,
                    investment_type: 3
                }
                setInvestmentSuggestion(data);
                if (mandate_check === true) {
                    if (response.data.mandate_status === true) {
                        toast.success("Mandate created successfully", {
                            type: "success",
                        });
                    }
                    else {
                        toast.error("Mandate creation failed", {
                            type: "error",
                        });
                    }
                }
            }
            setSubmitLoader(false);
        })
    }

    // API - update funds
    const _updateFunds = (basket_type, lookup_ids) => {
        const url = "/goal/update-funds";
        const request = {
            goal_id: props.basketTypeId,
            basket_type: basket_type,
            lookup_ids: lookup_ids
        };

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                _handleInvest();
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
        });
    }

    return (
        <Fragment>
            <div className="box-shadow-33 border-radius-32px border-lavender-blue py-4 bg-white">
                <div className="d-flex px-4 justify-content-between align-items-center mb-2">
                    <h6 className="e-font-18 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">Portfolio That Suits You</h6>

                </div>
                <div className="row">

                    <div className="col-xl-5 col-lg-6 pb-2">
                        <div className="col-11 d-sm-block d-none">
                            <div className="px-sm-4 px-3 padding-12px-tb d-flex gap-12px e-bg-lavender-light border-radius-trbr-40px">
                                <img src={props.investmentType === "Conservative" ? require("../../Assets/Images/risk-assessment/conservative.png") :
                                    props.investmentType === "Moderate" ? require("../../Assets/Images/risk-assessment/moderate.png") :
                                        props.investmentType === "Aggressive" ? require("../../Assets/Images/risk-assessment/aggressive.png") :
                                            require("../../Assets/Images/risk-assessment/very-aggressive.png")}
                                    alt="risk"
                                    width={56}
                                    height={56} />
                                <div className={`gap-2px d-flex flex-column e-font-18 e-line-height-24 e-montserrat-bold 
                                ${props.investmentType === "Conservative" ? 'color-ufo-green' :
                                        props.investmentType === "Moderate" ? "color-vivid-orange" :
                                            props.investmentType === "Aggressive" ? "color-tangerine" :
                                                "color-pastel-red"}`}>
                                    {props.investmentType}
                                    <span className="color-dark-gray e-montserrat-medium e-font-14 ">
                                        Below portfolio is uniquely designed to match your risk profile.
                                    </span>
                                </div>

                            </div>

                        </div>
                        <div className="col-12 ps-3 d-sm-none d-block">
                            <div className="px-3 padding-6px-tb d-flex align-items-center gap-12px e-bg-lavender-light border-radius-tlbl-40px">
                                <img src={props.investmentType === "Conservative" ? require("../../Assets/Images/risk-assessment/conservative.png") :
                                    props.investmentType === "Moderate" ? require("../../Assets/Images/risk-assessment/moderate.png") :
                                        props.investmentType === "Aggressive" ? require("../../Assets/Images/risk-assessment/aggressive.png") :
                                            require("../../Assets/Images/risk-assessment/very-aggressive.png")}
                                    alt="risk"
                                    width={52}
                                    height={52} />
                                <div className={`gap-2px d-flex flex-column e-font-16 e-line-height-24 e-montserrat-bold 
                                ${props.investmentType === "Conservative" ? 'color-ufo-green' :
                                        props.investmentType === "Moderate" ? "color-vivid-orange" :
                                            props.investmentType === "Aggressive" ? "color-tangerine" :
                                                "color-pastel-red"}`}>
                                    {props.investmentType}
                                    <span className="color-dark-gray e-montserrat-medium e-font-14 ">
                                        Below portfolio is uniquely designed to match your risk profile.
                                    </span>
                                </div>

                            </div>

                        </div>
                        {Object.keys(modalData).length > 0 && <div className="px-4 mt-3">
                            {/* <h6 className="color-eerie-black e-font-16 e-line-height-24 e-montserrat-medium my-3">Fund Breakdown</h6> */}
                            <HorizontalStackedBar allocations={portfolioData.graph_data !== undefined && portfolioData.graph_data} type={1} />
                        </div>}
                    </div>
                    <div className="col-xl-1 d-xl-block d-none">
                        <div className="e-seperator position-relative"></div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="pe-sm-4 pe-3 ps-lg-0 ps-sm-4 ps-3">
                            <div className="d-flex justify-content-between align-items-center margin-12px-bottom">

                                <h6 className="color-eerie-black e-font-16 e-line-height-24 e-montserrat-medium mb-0">Fund Name & Allocation</h6>
                                <button type="submit" className={`e-customize-btn e-font-14 e-line-height-18 e-montserrat-semi-bold py-sm-3 py-2 px-sm-3 px-2 d-flex gap-8px align-items-center ${Object.keys(modalData).length === 0 ? "disabled opacity-7" : ""}`} onClick={_openAllocationModal}>
                                    <img src={require("../../Assets/Images/Global/custominse.svg").default}
                                        draggable={false}
                                        alt="customize" />
                                    Customize
                                </button>
                            </div>
                            <div className="border-bottom-1px border-chinese-white">
                                <div className="row align-items-center">
                                    <div className="col-8 pe-0">
                                        <CustomTabBar data={TAB_DATA}
                                            selectedTab={tabIndex}
                                            onSelectTab={_handleTab} />
                                    </div>
                                    {portfolioData.allocation_data !== undefined && Object.keys(portfolioData.allocation_data).length > 0 && <div className="col-4 text-center">
                                        <span className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-medium">Allocation</span>
                                    </div>}
                                </div>
                            </div>
                            <div className="min-h-150px d-flex flex-column">
                                {
                                    portfolioData.allocation_data !== undefined && Object.keys(portfolioData.allocation_data).length > 0 ?
                                        Object.entries(portfolioData.allocation_data).map(([key, fund], index) => {
                                            return (
                                                fund.filter((item) => item.selected === true).map((item) => {
                                                    const dot_color = portfolioData.graph_data.find((asset) => asset.name === item.asset_class)
                                                    return (
                                                        <div className={index > 0 ? "padding-12px-tb border-top-1px border-onyx" : "padding-12px-tb"}>
                                                            <div className="row">
                                                                <div className="col-8 pe-0">
                                                                    <div className="d-flex align-items-start gap-8px">
                                                                        <div className="min-w-8px min-h-8px border-radius-100px mt-1" style={{ background: dot_color.color }}></div>
                                                                        <div className="e-font-14 e-line-height-18 e-montserrat-semi-bold">
                                                                            {item.name}
                                                                            <p className="e-montserrat-medium e-font-14 e-line-height-20 mb-0 color-outer-space margin-10px-top">{item.asset_class}</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4 text-center">
                                                                    <span className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold">{item.allocation} %</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        })
                                        :
                                        <div className="e-montserrat-medium e-font-14 e-line-height-20  color-outer-space my-auto">
                                            Since there are no passive funds available in suggested combination, kindly proceed with active funds. Passive funds shall be made available when we get such desired allocation
                                        </div>
                                }
                            </div>
                            {Object.keys(modalData).length > 0 &&
                                <Fragment>
                                    <div className="d-flex mt-md-3 mt-1">
                                        <img src={require("../../Assets/Images/Goal/compare.svg").default}
                                            alt="Compare"
                                            draggable={false}
                                            className="cursor-pointer"
                                            onClick={_handleCompare} />
                                        <span className="e-font-14 e-line-height-20 e-montserrat-semi-bold color-rebecca-purple ms-2 cursor-pointer"
                                            onClick={_handleCompare}>Compare Active Vs Passive Baskets</span>
                                    </div>

                                    <div className="d-flex mt-3 align-items-center e-bg-pale-green border-radius-12px px-3 py-2">
                                        <img src={require("../../Assets/Images/Portfolio/trophy.svg").default}
                                            alt="Compare"
                                            draggable={false}
                                            className="cursor-pointer"
                                        />
                                        <span className="e-font-12 e-line-height-18 e-montserrat-medium color-dark-green ms-2">Almost 70% people using this fund, so what are you waiting for!!</span>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-sm-flex d-block gap-16px justify-content-center align-items-center margin-38px-top">
                    {Object.keys(modalData).length > 0 && <div className="row justify-content-center">
                        <div className="col-md-2 col-sm-4 col-6 d-sm-none d-bock">
                            <PrimaryButton label="Invest Now"
                                className={"padding-12px-tb w-100 border-radius-40px"}
                                disabled={submitLoader}
                                onPress={() => {
                                    _handleInvest()
                                }} />
                        </div>
                    </div>}
                    <LinkButton label="Review your risk profile?"
                        className="d-flex justify-content-center e-font-16 e-line-height-24 mt-sm-0 mt-2"
                        onPress={() => _reviewAssessment(true)} />
                    {Object.keys(modalData).length > 0 && <div className="col-md-2 col-sm-4 col-6 d-sm-block d-none">
                        <PrimaryButton label="Invest Now"
                            className={"padding-12px-tb w-100 border-radius-40px"}
                            disabled={submitLoader}
                            onPress={() => {
                                _handleInvest()
                            }} />
                    </div>}
                </div>
            </div>
            {
                riskAssessment === true && <RiskAssessmentReview closeModal={() => _reviewAssessment(false)} />
            }

            {
                showFundAllocation === true &&
                <CustomFundAllocation fundData={modalData}
                    label="Save & Continue To Invest"
                    basket={tabIndex}
                    submitAllocation={_updateFunds}
                    closeModal={_closeAllocationModal} />
            }
            {
                showGoalModal === true &&
                <InvestMoreModal closeModal={_handleCloseInvestMoreModal}
                    isReinvest={false}
                    basketType={tabIndex}
                    showInfo={props.isInvested === true ? true : false}
                    isFirstInstallment={2}
                    basket={true}
                    selectedDate={_getTomorrowDate()}
                    selectedDateString={_formatSip(_getTomorrowDate())}
                    summary={investmentSuggestion}
                    invested={props.invested}
                    checkStatus={() => _getInvestmentSuggestions(goalInvestMore.goal_id, true)} />
            }
            {
                riskKycCheck === true && <RiskKycConfirmationModal
                    title="Open an Investment Account Now"
                    content="You are halfway through your account creation. Investment Account need to start with Investment."
                    closeModal={() => _confirmRiskKyc(false)}
                    handleConfirm={_handleNavigateToScreen} />
            }
        </Fragment>
    )
})

export default ModelPortfolioCard;
