/*
*   File : kyc-status.js
*   Author : https://evoqins.com
*   Description : Screen to show kyc status of user
*   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader } from "../../Components/Other";
import { PrimaryButton } from "../../Components/Buttons";

const KycStatus = () => {
    const navigate = useNavigate();
    const PROFILE_DATA = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const [pageLoader, setPageLoader] = useState(true);

    const [description, setDescription] = useState("");
    const [buttonLabel, setButtonLabel] = useState("");
    const [bannerImage, setBannerImage] = useState(null);

    useEffect(() => {
        if (Object.keys(PROFILE_DATA).length > 0) {
            setPageLoader(false);
            _getKycData()
        }
        // eslint-disable-next-line
    }, [PROFILE_DATA])

    function _handleNavigate() {
        navigate("/");
    }

    const _getKycData = (status) => {
        const profileData = PROFILE_DATA
        let image_url = require("../../Assets/Images/KycStatus/start-kyc.png")
        let kyc_description = "";
        let button_label = "";
        if (profileData.kyc.status === false && profileData.kyc.reason === null) {
            kyc_description = "Start investing now, Unlock the door to your financial freedom";
            image_url = require("../../Assets/Images/KycStatus/new-kyc.png")
        } else if (profileData.kyc.status === true) {
            kyc_description = "Congratulations! Your KYC is validated. Start investing now with MINTIT.";
            image_url = require("../../Assets/Images/KycStatus/validated.png")
        } else {
            switch (profileData.kyc.reason) {
                case "Unavailable":
                    button_label = "New KYC";
                    kyc_description = "Your KYC is not available, please proceed to create a new KYC.";
                    image_url = require("../../Assets/Images/KycStatus/unavailable.png");
                    break;
                case "Onhold":
                    button_label = "Modify"
                    kyc_description = "Your KYC application is on hold. Please modify via link below."
                    image_url = require('../../Assets/Images/KycStatus/on-hold.png');
                    break;
                case "Rejected":
                    button_label = "New KYC"
                    kyc_description = "Your KYC has been rejected. Please create a new KYC."
                    image_url = require('../../Assets/Images/KycStatus/rejected.png');
                    break;
                case "Deactivated":
                    button_label = ""
                    kyc_description = "Your KYC is deactivated, you are currently not eligible for any investment."
                    image_url = require('../../Assets/Images/KycStatus/deactivated.png');
                    break;
                case "Legacy":
                    button_label = "Modify"
                    kyc_description = "Your KYC is non-compliant with current regulations. Please modify it via the link below."
                    image_url = require('../../Assets/Images/KycStatus/leagacy.png');
                    break;
                case "Underprocess":
                    button_label = ""
                    kyc_description = "Your KYC application approval is under process at the KRA. Once it is registered and validated, you can start your investment."
                    image_url = require('../../Assets/Images/KycStatus/processing.png');
                    break;
                case "Incomplete":
                    button_label = "New KYC"
                    kyc_description = "Your KYC is incomplete. Please create a new KYC."
                    image_url = require('../../Assets/Images/KycStatus/incomplete.png');
                    break;
                case "Unknown":
                    button_label = "Modify"
                    kyc_description = "Probably your KYC is non-compliant."
                    image_url = require('../../Assets/Images/KycStatus/incomplete.png');
                    break;
                default:
                    button_label = ""
                    kyc_description = "An unknown error occurred."
                    image_url = null;
                    break;
            }
        }

        setBannerImage(image_url);
        setDescription(kyc_description);
        setButtonLabel(button_label)

    }

    function _handleKycNavigate() {
        let action = PROFILE_DATA.kyc.action;
        if (PROFILE_DATA.kyc.status === false && PROFILE_DATA.kyc.reason === null) {
            navigate('/kyc');
        } else {
            if (action === "Create" || action === "Resume" || action === "Rejected" || action === "Pending") {
                navigate('/kyc');
            } else if (action === "Modify") {
                window.openURL(PROFILE_DATA.kyc.validation_url, "_blank");
            }
        }
    }
    return (
        pageLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 pe-lg-0 padding-144px-bottom">
                            <div className="px-4">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Manage KYC"
                                    handleNavigate={_handleNavigate}
                                    type={1} />
                                <p className="e-montserrat-semi-bold e-font-18 e-line-height-38 color-dark-jungle-green margin-12px-bottom margin-30px-top">Manage KYC</p>
                                <div className="row row-gap-24px">
                                    <div className="col-lg-8">
                                        <div className="border-radius-24px e-profile-border padding-50px-tb text-center px-4">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6 ">
                                                    {bannerImage && <img src={bannerImage}
                                                        alt="Validated"
                                                        width={125}
                                                        height={125}
                                                        className="mx-auto img-fluid" />}


                                                    <h6 className="color-jet e-font-18 e-line-height-24 e-montserrat-semi-bold  mb-2">{PROFILE_DATA.kyc.reason ? PROFILE_DATA.kyc.reason : PROFILE_DATA.kyc.status_text}</h6>
                                                    <p className="color-jet e-font-16 e-line-height-24 e-montserrat-medium  mb-2">
                                                        {description}
                                                    </p>

                                                    {(PROFILE_DATA.kyc.status === false) &&
                                                        <div className="row justify-content-center">
                                                            <div className="col-6">
                                                                <PrimaryButton label={buttonLabel}
                                                                    type={1}
                                                                    onPress={() => _handleKycNavigate()} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        <p className="e-montserrat-regular e-font-16 e-line-height-30 color-davy-gray mb-0 mt-3">
                                            Need help:<br />
                                            If you are facing any difficulty in completing the KYC, please contact MINTIT team in the contact  <br /><a href="tel:+918956915040" rel="noreferrer" className="color-rebecca-purple e-link e-montserrat-regular">
                                                +91 8956915040
                                            </a> or <a href="mailto:helpdesk@mintit.in" className="color-rebecca-purple e-link e-montserrat-regular"
                                                rel="noreferrer">helpdesk@mintit.in</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    )
}

export default KycStatus