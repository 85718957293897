/*
*   File : refer-earn.js
*   Author : https://evoqins.com
*   Description : Refer a friend file
*   Version : 1.0.0
*/

// packages
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Breadcrumb } from "../../Components/Other";
import { Header } from "../../Components/Header";
import { CustomTextInput } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";

const ReferEarn = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [copied, setCopied] = useState(false);

    function _handleNavigate() {
        navigate("/");
    }


    function _handleName(input_value) {
        setName(input_value);
        setNameError("");
    }

    async function _handleShare(type) {

        let message = `Hey (${name}),
MINTIT, India’s most trusted digital wealth manager, is expertly guiding me in managing my wealth. Get started: ${process.env.REACT_APP_REFER_URL}`
        let valid = true;
        if (name === "") {
            setNameError("Name is required");
            valid = false;
        }
        if (valid === true) {
            if (type === 1) {
                navigator.clipboard.writeText(message).then(() => {
                    setCopied(true);
                    setTimeout(() => {
                        setCopied(false);
                    }, 200)
                })
            } else if (type === 2) {
                const encodedMessage = encodeURIComponent(message);
                const whatsappUrl = `https://web.whatsapp.com/?text=${encodedMessage}`;
                window.open(whatsappUrl, '_blank');
            } else {
                const encodedMessage = encodeURIComponent(message);
                const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedMessage}`;
                window.open(facebookUrl, '_blank', 'width=600,height=400');
            }
        }
    }


    return (
        <Fragment>
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <Header type={2} />
                <div className="row ">
                    <div className="col-lg-11 pe-lg-0 padding-144px-bottom">
                        <div className="px-4">
                            <Breadcrumb mainPage="Home"
                                currentPage="Refer & Earn"
                                handleNavigate={_handleNavigate}
                                type={1} />
                            <img src={require("../../Assets/Images/Support/invite-now.png")}
                                alt="Invite-Now"
                                draggable={false}
                                className="margin-80px-top w-100" />
                            <div className="e-bg-light-lavender border-radius-24px p-4">

                                <h6 className="color-dark-jungle-green e-font-24 e-montserrat-semi-bold e-line-height-32 mb-3">Invite Friends & Earn</h6>
                                <p className="color-blue-gray e-font-16 e-montserrat-regular e-line-height-24 mb-3">Simply enter the name you want to invite, and we'll help you send a personalized invitation to your friends.</p>
                                <div className="col-xl-4 col-lg-6 col-sm-6">
                                    <CustomTextInput label="Enter name"
                                        postfix="*"
                                        placeholder="Eg: John"
                                        handleChange={_handleName}
                                        value={name}
                                        error={nameError} />
                                </div>
                                <div className="border-top-1px border-bright-gray margin-38px-top" />
                                <div className="d-flex gap-16px align-items-center mt-4">
                                    <PrimaryButton label={copied ? "Copied...." : "Copy link"} image={require("../../Assets/Images/Support/copy.svg").default}
                                        className="padding-20px-lr"
                                        onPress={() => _handleShare(1)} />
                                    <img src={require("../../Assets/Images/Support/fb.svg").default}
                                        alt="Fb"
                                        className="cursor-pointer"
                                        onClick={() => _handleShare(3)} />
                                    <img src={require("../../Assets/Images/Support/whatsapp.svg").default}
                                        alt="Whatsapp"
                                        className="cursor-pointer"
                                        onClick={() => _handleShare(2)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ReferEarn